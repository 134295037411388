<template>
  <div class="client m-p-t-47">
    <el-form ref="Form" :model="dataForm" :rules="rules" label-width="120px">
      <el-form-item label="手机号码" prop="mobilePhone">
        <el-input
          v-model="dataForm.mobilePhone"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="企业名称" prop="verificationCode">
        <el-input
          v-model="dataForm.verificationCode"
          placeholder="请输入验证码"
        >
          <template #append>
            <div class="verificationCode" @click="getCode">获取验证码</div>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="创建密码" prop="password">
        <el-input
          v-model="dataForm.password"
          placeholder="设置登录密码"
          show-password
        />
      </el-form-item>
      <el-form-item prop="fChecked">
        <div class="checkbox">
          <el-checkbox v-model="dataForm.fChecked"></el-checkbox>
          <span style="margin-left: 10px">我已阅读并接受<el-button type="text" @click="dialogVisible = true">盘雷服务使用协议</el-button>条款</span>
        </div>
      </el-form-item>
    </el-form>
    <el-button
      class="btn"
      style="margin-top: 12px"
      type="primary"
      @click="handleComfirm"
      >下一步</el-button
    >
     <el-dialog
    v-model="dialogVisible"
    title="Tips"
    width="30%"
    :before-close="handleClose"
  >
    <span>This is a message</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >Confirm</el-button
        >
      </span>
    </template>
  </el-dialog>
  </div>
</template>

<script>
/***
 * @Author zhd
 * @Date 2021-10-22
 * @desc 客户注册
 */
import { ref, reactive, toRaw } from "vue";
import { useRouter } from "vue-router";
import store from '@/store.js'  //引入状态管理模块
import { ElMessage } from "element-plus";
import Qs from 'qs'

function MESSAGE (type = 'erroe', title = '出错了') {
  ElMessage[type](title)
  return false
}
const rules = {
  mobilePhone: [
    {
      required: true,
      message: "手机号不能为空",
      trigger: "blur",
    },
    {
      pattern: /(^1[3|4|5|7|8][0-9]{9}$)/,
      transform(value) {
        // 先将值转换成字符串
        return String(value);
      },
      message: "请输入有效的手机号码",
      trigger: ["blur"],
    },
  ],
  password: [
    {
      required: true,
      message: "密码不能为空",
      trigger: "blur",
    },
  ],
  verificationCode: [
    {
      required: true,
      message: "验证码不能为空",
      trigger: "blur",
    },
  ],
  fChecked: [
    {
      required: true,
      message: "请接受条款",
      trigger: "blur",
    },
    {
      pattern: /true/,
      message: "请接受条款",
      trigger: ["blur"],
    },
  ],
};
export default {
  setup() {
    const dataForm = reactive({
      mobilePhone: "", // 手机号
      password: "", // 密码
      verificationCode: "", // 验证码
      fChecked: false, // 条款是否选中
    });
    const Form = ref("");
const dialogVisible = ref(false)
    const router = useRouter();

    const getCode = () => {
      if(!dataForm.mobilePhone) {
        return MESSAGE('warning', '请先填写手机号')
      }
      store.dispatch("loginModule/smsCode", dataForm.mobilePhone);
    }
    
    //客户注册
    const handleComfirm = () => {
      Form.value.validate( async (valid) => {
        if (valid) {
          // 校验验证码
          let data = {
            phone: dataForm.mobilePhone, 
            code: dataForm.verificationCode
          }

          let smsCode = await store.dispatch("loginModule/smsCodeV", '?' + Qs.stringify(data)).then(res => res)
          // 注册失败 验证码验证失败
          if(smsCode.data.data !== null) {
            return  MESSAGE('warning', smsCode.data.data.msg)
          }
          // 注册
          let register = await store.dispatch('loginModule/register',{
            inviter: 'xxx',
            inviterEnterpriseId: 'xxxxxx',
            userType: '0',
            ...toRaw(dataForm)
          })
          // 注册失败
          if(register.data.data){
            return MESSAGE('warning', register.data.data.msg)
          }
          router.push(`/login/register/distributor?userId=${123}`)
        } else {
          return false;
        }
      });
    };

    return {
      dataForm, // 表单数据
      Form, // 表单ref
      rules, // 规则
      getCode, // 获取验证码
      handleComfirm, // 注册方法
    };
  },
};
</script>
<style lang="less" scoped>
.m-p-t-47 {
  padding-top: 47px;
}
.client {
  width: 500px;
  margin: 0 auto;
}
.btn {
  width: 400px;
  height: 50px;
  display: block;
  margin: 0 auto;
}
.verificationCode{
  cursor: pointer;
}
</style>